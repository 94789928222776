<template>
    <div>
        <b-row>
          <b-col class="content-header-left mb-2">
            <div class="d-flex justify-content-between align-items-center">
              <h2>Prospects</h2>
            </div>
          </b-col>
          <b-col class="justify-content-end align-items-center d-flex">
            <b-button class="float-right" @click="openModal" data-cy="addCompanyButton" variant="primary" size="sm">
              <feather-icon icon="PlusIcon" />
              INVITE A PRODUCER
            </b-button>
          </b-col>
        </b-row>
        <b-row>
            <b-col cols="6" class="my-1">
                <b-form-group class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input
                            id="filterInput"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                        />
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">
                                Clear
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="2" class="my-1 custom-control custom-switch">
                <div class="switch-container">
                    <input type="checkbox"
                           class="custom-control-input"
                           id="allSwitch"
                           v-model="showProducers">
                    <label class="custom-control-label" for="allSwitch"></label>
                    <span>Show producers</span>
                </div>
            </b-col>


          <validation-observer ref="producerValidation" v-slot="{ passes }">
            <b-modal
                id="createProducer-modal"
                ref="create-modal"
                title="Create Producer"
                ok-title="Create"
                @ok="submitProducer"
                @ok.prevent="passes()"
                data-cy="submitInvitation"
            >
              <div>
                <b-row class="pb-1">
                  <b-col cols="6">
                    <validation-provider
                        v-slot="{ errors }"
                        name="first name"
                        rules="required"
                    >
                      <label>First name <sup class="text-danger">*</sup></label>
                      <b-form-input
                          name="firstName"
                          id="firstName"
                          v-model="form.firstName"
                          placeholder=""
                          type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="6">
                    <validation-provider
                        v-slot="{ errors }"
                        name="last name"
                        rules="required"
                    >
                      <label>Last name <sup class="text-danger">*</sup></label>
                      <b-form-input
                          name="lastName"
                          id="lastName"
                          v-model="form.lastName"
                          placeholder=""
                          type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="pb-1">
                    <b-col cols="12">
                      <validation-provider
                          v-slot="{ errors }"
                          name="email"
                          rules="required"
                      >
                        <label>Work email <sup class="text-danger">*</sup></label>
                        <b-form-input
                            name="email"
                            id="email"
                            v-model="form.email"
                            placeholder="email@your-company.com"
                            type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                </b-row>
                <b-row class="pb-1">
                  <b-col cols="6">
                    <validation-provider
                        v-slot="{ errors }"
                        name="company name"
                        rules="required"
                    >
                      <label>Company name <sup class="text-danger">*</sup></label>
                      <b-form-input
                          name="companyName"
                          id="companyName"
                          v-model="form.companyName"
                          placeholder=""
                          type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="6">
                      <label>Phone:</label>
                      <b-form-input
                          name="phone"
                          id="phone"
                          v-model="form.phone"
                          placeholder=""
                          type="text"
                      />
                  </b-col>
                </b-row>
              </div>
            </b-modal>
          </validation-observer>


            <b-col cols="2" class="my-1 custom-control custom-switch">
                <div class="switch-container">
                    <input type="checkbox"
                           class="custom-control-input"
                           id="approvedSwitch"
                           v-model="showApproved">
                    <label class="custom-control-label" for="approvedSwitch"></label>
                    <span>Show approved prospects</span>
                </div>
            </b-col>
            <b-col
                cols="2"
                class="my-1 justify-content-end align-items-center"
            >
                <b-form-group class="mb-0">
                    <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        :options="pageOptions"
                        size="sm"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table
                    striped
                    hover
                    responsive
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="prospects"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                >

                    <template #cell(full_name)="data">
                        <div
                            class="qf-user-details d-flex align-items-center"
                            @click="viewProfile(data.item)"
                        >
                            <b-avatar
                                badge-variant=""
                                badge-top
                                badge-offset="-0.5em"
                                v-b-tooltip.hover
                                :title="prospectLabel(data.item)"
                                :src="data.item.avatar ? data.item.avatar.url : null"
                                size="56"
                                class="mr-1"
                                :text="applicantInitials(data.item)">
                                <template #badge>
                                    <div :class="prospectBadgeVariant(data.item)">
                                        <feather-icon :icon="prospectStatusIcon(data)"/>
                                    </div>
                                </template>
                            </b-avatar>
                            <div>
                                <h5 class="mb-0">
                                    {{data.item.firstName + ' ' + data.item.lastName}}
                                </h5>
                                <div class="d-flex">
                                    ({{data.item.userType}})
                                </div>
                            </div>
                        </div>
                    </template>

                    <template #cell(email)="data">
                        <a :href="`mailto:`+data.item.email">{{ data.item.email }}</a>
                    </template>

                    <template #cell(phoneNumber)="data">
                        <a :href="`tel:`+data.item.phoneNumber">{{ data.item.phoneNumber }}</a>
                    </template>

                    <template #cell(createdAt)="data">
                        {{ niceDate(data.item.createdAt) }}
                    </template>

                    <template #cell(activationCode)="data">
                        <feather-icon icon="CopyIcon"
                                      @click="copyToClipboard(data.item.activationCode)"
                                      class="text-primary cursor-pointer"
                                      v-if="data.item.activationCode"
                        /> {{ data.item.activationCode}}
                    </template>

                    <template #cell(emailVerified)="data">
                        <div class="qf-controls ml-1 d-flex justify-content-between"
                        >
                            <feather-icon
                                icon="CheckCircleIcon"
                                size="18"
                                @click="approveProspect(data)"
                                :class="data.item.emailVerified? 'text-disabled cursor-pointer' : 'text-primary cursor-pointer'"
                            />
                            <feather-icon
                            icon="XIcon"
                            size="18"
                            v-b-modal.delete-live-audition-modal
                            @click="setUserToDelete(data)"
                            class="text-primary cursor-pointer"
                        />
                        </div>

                    </template>

                </b-table>
            </b-col>
            <b-col cols="12">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="ceperPagenter"
                    size="sm"
                    class="my-0"
                />
            </b-col>
        </b-row>
        <b-modal
            id="delete-live-audition-modal"
            ok-variant="danger"
            ok-title="Delete"
            cancel-title="Cancel"
            cancel-variant="outline-secondary"
            modal-class="modal-danger"
            size="sm"
            button-size="sm"
            centered
            title="Delete User"
            @ok="deleteProspect()"
        >
            <div class="modal-body text-center mb-2" v-if="userToDelete">
                <b-avatar
                    badge-variant=""
                    badge-top
                    badge-offset="-0.5em"
                    v-b-tooltip.hover
                    title="Shortlisted for role"
                    :src="userToDelete.avatar ? userToDelete.avatar.url : null"
                    size="56"
                    class="mr-1"
                    :text="applicantInitials(userToDelete)">
                    <template #badge>
                        <div class="shortlisted-mark">
                            <feather-icon icon="CheckIcon"/>
                        </div>
                    </template>
                </b-avatar>
                <div>
                    <h5 class="mb-0">
                        {{userToDelete.firstName + ' ' + userToDelete.lastName}}
                    </h5>
                    <div class="d-block">
                        ({{userToDelete.userType}})
                    </div>
                </div>
            </div>

            <b-card-text class="text-center">
                Are you sure you want to delete this user?<br/><br/>
                They will no longer have access to the platform and will have to register again.
            </b-card-text>
        </b-modal>
    </div>
</template>

<script>
import store from "@/store";
//import ModalReviewAudition from "@/modals/ModalReviewAudition";
import {
    BRow,
    BCol,
    //BCard,
    BTable,
    BPagination,
  //BSidebar,
  //BBadge,
  //BIcon,

  BAvatar,
  // BAvatarGroup,
  // BProgress,
  // BProgressBar,
  BModal,
  //BDropdown,
  //BDropdownItem,
    BFormSelect,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
  // BFormSelectOption,
    BCardText,

  // BFormDatepicker,
  // BFormTimepicker,
  // BFormTextarea,
    BFormInput,
  // VBToggle,
    VBTooltip,
    VBModal,
//    BButton,
} from 'bootstrap-vue'
import { required } from "@validations";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Api2 from "@/services/api2";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import moment from "moment";
import Parse from "parse";


//import Parse from "parse";
//import Api from "@/services/api";
//import moment from "moment-timezone";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol,
        BTable,
        BPagination,
        BAvatar,
        BModal,
        BFormInput,
        BFormSelect,
        BFormGroup,
        BInputGroup,
        BInputGroupAppend,
        BButton,
        BCardText,
    },
    data() {
      return {
          required,
          prospects: [],
          producers: [],

          showProducers: false,
          showApproved: false,
          userToDelete: null,

          perPage: 50,
          pageOptions: [10, 20, 50, 100],
          //totalRows: 1,
          currentPage: 1,
          sortBy: "",
          sortDesc: false,
          sortDirection: "asc",
          filter: null,
          filterOn: [],

          fields: [
              {
                  key: "full_name",
                  label: "Full Name",
                  sortable: true,
                  tdClass: "avatar-fullname",
              },
              {
                  key: "email",
                  label: "Email",
                  sortable: true,
                  tdClass: "email",
              },
              {
                  key: "phoneNumber",
                  label: "Phone",
                  sortable: true,
                  tdClass: "phone",
              },
              {
                  key: "companyName",
                  label: "Company",
                  sortable: true,
                  tdClass: "company",
              },
              {
                  key: "createdAt",
                  label: "Sign-up date",
                  sortable: true,
                  tdClass: "avatar-created_at",
              },
              {
                  key: "activationCode",
                  label: "Activation Code",
                  sortable: true,
                  tdClass: "avatar-updated_at",
              },
              {
                  key: "emailVerified",
                  label: "Actions",

                  tdClass: "verified",
              },
          ],
          form:{
            firstName: '',
            lastName: '',
            email: '',
            companyName: '',
            password: ''
          }
      }
    },
    directives: {
//        'b-toggle': VBToggle,
        'b-tooltip': VBTooltip,
        'b-modal': VBModal,
    },
    props: {
        listMode: {
            type: Boolean,
            default(){
                return true;
            }
        },
    },
    mounted() {
        this.curUser = Parse.User.current();
        const userObj = {
          sessionToken: this.curUser.attributes.sessionToken
        }
        Api2.sendData('getMyRoles', userObj).then((res) => {
          if (res.data.roles && res.data.roles.indexOf('PlatformOwner') > -1) {
            this.getProspects()
            store.commit('app/SET_IS_ADMIN', true);
          }else{
            this.$router.push({path: '/error-403'});
          }
        });
    },
    computed: {

        members(){
            return store.getters['app/allMembers']
        },
        curRole() {
            return store.getters['app/currentRole'];
        },
        totalRows() {
            return this.prospects.length;
        },
    },
    methods: {
        openModal(){
          this.$bvModal.show('createProducer-modal');

        },
        createProducer(){
            let self = this;
            this.form.sessionToken = this.curUser.attributes.sessionToken;
            Api2.sendData('createProducer', this.form).then((res) => {
              if (res.data.success) {
                this.$bvModal.hide('createProducer-modal');

                if(res.data.id){
                    let payload = {
                        prospectId: res.data.id,
                    }
                    Api2.sendData('approveProspect', payload).then(() => {
                        self.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Producer invited and activated successfully',
                                icon: "CheckIcon",
                                variant: "success",
                            },
                        });
                        self.showApproved = true;
                    });

                }

              }else{
                  self.$toast({
                      component: ToastificationContent,
                      props: {
                          title: "Check all fields for valid data!",
                          icon: "XIcon",
                          variant: "danger",
                      },
                  });
              }
            }).catch((err) => {
                self.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Check all fields for valid data!",
                        icon: "XIcon",
                        variant: "danger",
                    },
                });
              console.log(err);
            });

        },
        submitProducer(){
          let self = this;
          this.$refs.producerValidation
              .validate()
              .then((success) => {
                if (success) {
                  self.createProducer();
                  this.$refs['create-modal'].hide();
                }
              })
              .catch(function (err) {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Check all fields for valid data!",
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
                console.log("validation errors", err);
              });
        },
        prospectBadgeVariant(prospect){
            if(!prospect.emailVerified && prospect.userType == 'prospect'){
                return 'pending-mark'
            } else if(prospect.emailVerified && prospect.userType == 'prospect'){
                return 'selected-mark'
            } else {
                return 'shortlisted-mark'
            }
        },

        prospectLabel(prospect){
            if(prospect.emailVerified && prospect.userType == 'producer'){
                return 'Onboarded'
            } else if (prospect.emailVerified && prospect.userType == 'prospect') {
                return "Approved but hasn't joined yet"
            }else {
                return 'Pending review'
            }
        },

        prospectStatusIcon(prospect){
            if(prospect.item.emailVerified){
                return 'CheckIcon'
            }
            return 'HelpCircleIcon'
        },

        setUserToDelete(dataObj){
            this.userToDelete = dataObj.item;

        },

        applicantInitials(prospect){
            if(prospect){
                return prospect.firstName.substring(0,1) + prospect.lastName.substring(0,1);
            }else{
                return "";
            }
        },

        copyToClipboard(activationCode){
            navigator.clipboard.writeText(activationCode).then(function() {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Authorization code copied to clipboard.',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
            })
        },

        getProspects() {
            let payload = {
                showProducers: this.showProducers,
                showApproved: this.showApproved,
            }
            console.log(payload);
            Api2.sendData('getProspects', payload).then(response => {
                this.prospects = Object.values(response.data.prospects);
            }).catch(error => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Unable to fetch prospects. Network or server error.',
                        icon: 'XIcon',
                        variant: 'danger',
                    },
                });
                console.log(error);
            });
        },

        deleteProspect(){

            let self = this;

            let payload = {
                prospectId: this.userToDelete.objectId,
            }

            Api2.sendData('deleteProspect', payload).then(() => {
                //this.prospects = Object.values(response.data.prospects);

                self.getProspects();

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Prospect / User Deleted',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                });
            }).catch(error => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Unable to delete user.An error has occurred',
                        icon: 'XIcon',
                        variant: 'danger',
                    },
                });
                console.log(error);
            });

        },

        approveProspect(data) {

            if(data.item.emailVerified){
                return;
            }else {
                let self = this;
                let payload = {
                    prospectId: data.item.objectId,
                }
                Api2.sendData('approveProspect', payload).then(() => {
                    self.prospects[data.index].emailVerified = true;
                    self.prospects = [... self.prospects];

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'User approved to onboard.',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    });

                }).catch(error => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Unable to approve user. Network or server error.',
                            icon: 'XIcon',
                            variant: 'danger',
                        },
                    });
                    console.log(error);
                });

            }


        },

        onFiltered() {
            this.currentPage = 1;
        },

        viewProfile(talentRow) {
            if (talentRow.fullEntry) {
                store.commit("app/SET_TALENT", talentRow.fullEntry);
            } else {
                store.commit("app/SET_TALENT", talentRow);
            }
            this.profileId = talentRow.id;
        },
        niceDate(parseDate) {
            return moment(parseDate).format("DD-MMM-YYYY");
        },

    },
    watch: {
        showProducers() {
            this.getProspects();
        },
        showApproved() {
            this.getProspects();
        },
    }
}
</script>

<style scoped>
.qf-user-details p {
    color: #b4b7bd;
    size: 12px;
}

.switch-container {
    padding-top: 5px;
}
</style>
